import React from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import Item from './Item';
import Header from './Header';
import Hero from './Hero';
import Filters from './Filters';
import NoBookmarks from '../../../assets/images/academy-no-bookmarks.svg';
import { renderTotalPages } from '../../services/pages';

export default function Bookmarks({
  currentUser, items, totalPages, currentPage, totalCount, isAdmin, tags, topics, academySubscription, subscription, userSession,
}) {
  return (
    <div className="react-lc-page lc-bookmarks">
      <Header currentUser={currentUser} isAdmin={isAdmin} academySubscription={academySubscription} userSession={userSession} />
      <Hero
        title="Bookmarks"
        subtitle="This page includes all content you have bookmarked in Academy."
      >
        <Filters tags={tags} topics={topics} />
      </Hero>
      { items?.length > 0 && (
        <div className="results-count">
          { (currentPage - 1) * 25 + 1 }
          {' - '}
          { Math.min(currentPage * 25, totalCount || 0) }
          {' of '}
          {totalCount || 0}
          {' '}
          result
          { totalCount > 1 ? 's' : ''}
        </div>
      )}

      <div className="section">
        { items?.length === 0 && (
          <div className="empty">
            You do not have any bookmarked content yet.
            <br />
            To add content to your Bookmarks, navigate to any content in the Academy
            and click on the bookmark symbol in the upper right hand corner. Once the
            bookmark turns yellow, that content will be displayed here.
            <br />
            <img style={{ marginTop: 45 }} src={NoBookmarks} alt="no-bookmarks" />
          </div>
        )}
        <div className="items">
          {items?.map((item) => (
            <Item data={item} isFavorite key={item.id} breadcrumb="favorites" isAdmin={isAdmin} isAcademy={academySubscription} subscription={subscription} />
          ))}
        </div>

        { items?.length > 0 && (
          <div className="pagination">
            <a
              className={`page arrow ${currentPage <= 1 && 'disabled'}`}
              href={`${window.location.pathname}?page=${currentPage - 1}`}
            >
              <FiChevronLeft />
            </a>
            {renderTotalPages(totalPages, currentPage)}
            <a className={`page arrow ${currentPage >= totalPages && 'disabled'}`} href={`${window.location.pathname}?page=${currentPage + 1}`}>
              <FiChevronRight />
            </a>
          </div>
        ) }
      </div>
    </div>
  );
}
