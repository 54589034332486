import React, { useEffect, useState } from 'react';
import {
  GoListUnordered, FaRegBookmark, FaHistory, FaUserCircle, FaPowerOff, FaExclamationCircle,
} from 'react-icons/all';
import LoginModal from '../login_modal/LoginModal';
import FeedbackModal from './FeedbackModal';
import SessionModal from './SessionModal';
import 'rc-menu/assets/index.css';
import { UserMenu } from './UserMenu';

export default function Header({ isAdmin, currentUser, userSession }) {
  if (window.location.pathname.includes('/academy') || window.location.pathname.includes('/media_category')) return null;
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [mobileHeaderOpen, setMobileHeaderOpen] = useState(false);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [sessionModalOpen, setSessionModalOpen] = useState(false);

  const setSessionTimeout = (date) => {
    if (!currentUser || isAdmin) return;

    const targetDate = new Date(date);
    const currentDate = Date.now();
    const dateDifference = currentDate - targetDate;
    // 90 minutes (90 * 60_000) = 5_400_000 milliseconds
    const parsedUserSession = Number(userSession || 90) * 60_000;

    if (dateDifference > parsedUserSession) {
      console.log('The target date is above 90 mins. Showing model.');
      setSessionModalOpen(true);
    } else {
      const logoutTime = new Date(targetDate.getTime() + parsedUserSession);
      console.log(`The target date is under 90 mins. Starting timeout. Logout at ${logoutTime}`);
      setTimeout(() => setSessionModalOpen(true), parsedUserSession - dateDifference);
    }
  };

  useEffect(() => setSessionTimeout(currentUser?.current_sign_in_at), []);

  const mobileHeaderToggle = () => {
    // disable page scroll if mobile header opened
    if (!mobileHeaderOpen) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';
    setMobileHeaderOpen(!mobileHeaderOpen);
  };
  const convertToTitleCase = (str) => str.replace(/_/g, ' ').replace('/', '').replace(/\b\w/g, (char) => char.toUpperCase()).split('/')[0];

  return (
    <>
      <div className="mobile-header">
        <div className="mobile-header-open" onClick={mobileHeaderToggle} />
        <div className="mobile-header-page">{ convertToTitleCase(window.location.pathname) }</div>
      </div>
      <div className={`react-header ${mobileHeaderOpen ? 'opened' : ''}`}>
        <a href="/"><img className="react-header-logo" src="/logo.svg" alt="" /></a>
        <div className="close-mobile" onClick={mobileHeaderToggle} />
        <div className="mobile-separator" />
        <div
          className="pages"
          onClick={(e) => {
            if (currentUser != null) return;
            e.preventDefault();
            setLoginModalOpen(!loginModalOpen);
          }}
          data-testid="eda-header"
        >
          <a href="/issue_dashboard" className={`${window.location.pathname.includes('/issue_dashboard') ? 'active' : ''}`}>
            <div className="icon mobile-icon issues" />
            Issues
          </a>
          <a href="/case_law" className={`${window.location.pathname.includes('/case_law') ? 'active' : ''}`}>
            <div className="icon mobile-icon case_digests" />
            Case Law
          </a>
          <a href="/rules" className={`${window.location.pathname === '/rules' ? 'active' : ''}`}>
            <div className="icon mobile-icon rules" />
            Rules
          </a>
          <a href="/checklists" className={`${window.location.pathname === '/checklists' ? 'active' : ''}`}>
            <div className="icon mobile-icon checklists_and_forms" />
            Checklists & Forms
          </a>
          <a href="/glossary_terms" className={`${window.location.pathname === '/glossary_terms' ? 'active' : ''}`}>
            <div className="icon mobile-icon glossary_terms" />
            Glossary
          </a>
          <a href="/academy" target="_blank">
            <div className="icon mobile-icon academy" />
            Academy
          </a>
        </div>
        <div className="mobile-separator" />
        <div className="user-items">
          {
            !currentUser && (
              <div className="cite-list user-item">
                <a href="/login">Login</a>
              </div>
            )
          }
          {
          currentUser && (
          <>
            <div className="cite-list user-item mobile-profile">
              <FaUserCircle className="icon" />
              &nbsp;&nbsp;
              <a href="/account/edit#profile">
                { currentUser.first_name }
                {' '}
                { currentUser.last_name }
              </a>
            </div>
            <div className="cite-list user-item">
              <FaRegBookmark className="icon" />
              &nbsp;&nbsp;
              <a href="/favorites">Bookmarks</a>
            </div>
            <div className="cite-list user-item">
              <FaHistory className="icon" />
              &nbsp;&nbsp;
              <a href="/history">History</a>
            </div>
            <div className="cite-list user-item">
              <GoListUnordered />
              &nbsp;&nbsp;
              <a href="/cite_lists">My Cite Lists</a>
            </div>
            <UserMenu currentUser={currentUser} isAdmin={isAdmin} openFeedbackModal={() => setFeedbackModalOpen(true)} />
          </>
          )
        }
        </div>
        <div className="mobile-separator" />
        <div
          className="pages mobile-block"
          onClick={(e) => {
            if (currentUser != null) return;
            e.preventDefault();
            setLoginModalOpen(!loginModalOpen);
          }}
        >
          <a onClick={() => setFeedbackModalOpen(true)}>
            <FaExclamationCircle className="icon mobile-icon" />
            Send Feedback
          </a>
          <a href="/admin">
            <FaUserCircle className="icon mobile-icon" />
            Admin
          </a>
          <a rel="nofollow" data-method="delete" href="/logout">
            <FaPowerOff className="icon mobile-icon" />
            Logout
          </a>
        </div>
        { loginModalOpen && (<LoginModal closeModal={() => setLoginModalOpen(!loginModalOpen)} />)}
        { feedbackModalOpen && <FeedbackModal closeModal={() => setFeedbackModalOpen(false)} /> }
        { sessionModalOpen && (
          <SessionModal closeModal={() => {
            setSessionModalOpen(false);
            setSessionTimeout(Date.now());
          }}
          />
        )}
      </div>
    </>
  );
}
