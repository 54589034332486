import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import AbaRules from './src/AbaRules';
import FederalRules from './src/FederalRules';
import FederalRulesNavigator from './src/FederalRulesNavigator';
import StateRules from './src/StateRules';
import StateRulesNavigator from './src/StateRulesNavigator';
import DistrictRules from './src/DistrictRules';
import RegulatoryRules from './src/RegulatoryRules';
import RegulatoryRulesNavigator from './src/RegulatoryRulesNavigator';
import api from '../../services/api';
import DistrictRulesNavigator from './src/DistrictRulesNavigator';
import AAARules from './src/AAARules';
import InternationalRules from './src/InternationalRules';

class Rules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRules: 'Federal',
      federalFilter: 'All',
      regulatoryFilter: 'All',
      stateFilter: '',
      stateRuleCategoryFilter: '',
      districtStateFilter: '',
      districtFilter: '',
      favorites: {},
      abaRules: [],
      regulatoryAgencies: [],
      states: [],
      districtStates: [],
      federalRuleCategories: [],
      aaaRules: [],
      internationalRules: [],
      loading: true,
      mobileOpened: false,
    };
  }

  componentDidMount() {
    const { favorites } = this.props;

    const _searchParams = new URLSearchParams(window.location.search);

    const _selectedRules = _searchParams.get('selectedRules')
      ? new URLSearchParams(window.location.search).get('selectedRules') : 'Federal';
    const _stateFilter = _searchParams.get('state')
      ? new URLSearchParams(window.location.search).get('state') : '';
    const _districtStateFilter = _searchParams.get('districtState')
      ? new URLSearchParams(window.location.search).get('districtState') : '';
    const _districtFilter = _searchParams.get('district')
      ? new URLSearchParams(window.location.search).get('district') : 'All';
    this.setState({
      favorites,
      selectedRules: _selectedRules,
      stateFilter: _stateFilter,
      districtStateFilter: _districtStateFilter,
      districtFilter: _districtFilter,
    });
    switch (_selectedRules) {
      case 'Federal':
        api.rules.federal.get().then((res) => this.setState({ federalRuleCategories: res.message, loading: false }));
        break;
      case 'AAA':
        api.rules.aaa.get().then((res) => this.setState({ aaaRules: res.message, loading: false }));
        break;
      case 'International':
        api.rules.international.get().then((res) => this.setState({ internationalRules: res.message, loading: false }));
        break;
      case 'ABA':
        api.rules.aba.get().then((res) => this.setState({ abaRules: res.message, loading: false }));
        break;
      case 'State courts':
        api.rules.state.get(_stateFilter).then((res) => this.setState({ states: res.message, loading: false }));
        break;
      case 'District courts':
        api.rules.district.get(_districtStateFilter).then((res) => this.setState({ districtStates: res.message, loading: false }));
        break;
      case 'Regulatory':
        api.rules.regulatory.get().then((res) => this.setState({ regulatoryAgencies: res.message, loading: false }));
        break;
      default:
        break;
    }
  }

  _filterStates = () => {
    const { states, stateFilter } = this.state;
    return stateFilter === '' ? null : states.filter((state) => state.name === stateFilter);
  };

  _setDistrictFilter = (filter) => {
    const { districtStateFilter } = this.state;
    if (districtStateFilter === '') return;
    if (filter === '') {
      this.setState({ districtFilter: filter });
      return;
    }

    const _params = new URLSearchParams(window.location.search);
    _params.set('district', filter);
    window.history.pushState({}, '', `/rules?${_params.toString()}`);
    this.setState({ districtFilter: filter });
  };

  _setFederalFilter = (filter) => {
    this.setState({ federalFilter: filter });
  };

  _setRegulatoryFilter = (filter) => {
    this.setState({ regulatoryFilter: filter });
  };

  _setStateFilter = (filter) => {
    if (filter === '') {
      this.setState({ stateFilter: filter });
      return;
    }
    const { states } = this.state;
    const { allStates } = this.props;

    const _params = new URLSearchParams(window.location.search);
    _params.set('state', filter);
    window.history.pushState({}, '', `/rules?${_params.toString()}`);
    this.setState({ stateFilter: filter, stateRuleCategoryFilter: 'All' });

    if (!states.some((state) => state.name === filter) && allStates.some((state) => state.name === filter)) {
      api.rules.state.get(filter).then((res) => {
        if (res.message[0] === undefined) return;
        states.push(res.message[0]);
        this.setState({ states });
      });
    }
  };

  _setStateRuleCategoryFilter = (filter) => {
    this.setState({ stateRuleCategoryFilter: filter });
  };

  _setDistrictStateFilter = (filter) => {
    if (filter === '') {
      this.setState({ districtStateFilter: filter });
      return;
    }
    const { districtStates } = this.state;
    const { allStates } = this.props;

    const _params = new URLSearchParams(window.location.search);
    _params.set('districtState', filter);
    window.history.pushState({}, '', `/rules?${_params.toString()}`);
    this.setState({ districtStateFilter: filter });

    if (!districtStates.some((state) => state.name === filter) && allStates.some((state) => state.name === filter)) {
      api.rules.district.get(filter).then((res) => {
        if (res.message[0] === undefined) return;
        districtStates.push(res.message[0]);
        this.setState({ districtStates });
      });
    }
  };

  _selectRules = (e) => {
    document.location = `rules?selectedRules=${e.target.value}`;
  };

  _updateFavorites = () => {
    api.favorites.get().then((result) => {
      this.setState({ favorites: result.favorites });
    });
  };

  _toggleRuleBookmark = (e, rule) => {
    e.stopPropagation();
    e.preventDefault();
    const { selectedRules, favorites } = this.state;
    const ruleId = rule.rule_id;
    const isBookmark = favorites.rules !== undefined && favorites.rules.some(
      (favoriteRule) => favoriteRule.id === ruleId,
    );
    switch (selectedRules) {
      case 'Federal':
        if (isBookmark) api.rules.federal.unfavorite(ruleId);
        else api.rules.federal.favorite(ruleId);
        break;
      case 'State courts':
        if (isBookmark) api.rules.state.unfavorite(ruleId);
        else api.rules.state.favorite(ruleId);
        break;
      case 'District courts':
        if (isBookmark) api.rules.district.unfavorite(ruleId);
        else api.rules.district.favorite(ruleId);
        break;
      case 'ABA':
        if (isBookmark) api.rules.aba.unfavorite(ruleId);
        else api.rules.aba.favorite(ruleId);
        break;
      case 'AAA':
        if (isBookmark) api.rules.aaa.unfavorite(ruleId);
        else api.rules.aaa.favorite(ruleId);
        break;
      case 'Regulatory':
        if (isBookmark) api.rules.regulatory.unfavorite(ruleId);
        else api.rules.regulatory.favorite(ruleId);
        break;
      case 'International':
        if (isBookmark) api.rules.international.unfavorite(ruleId);
        api.rules.international.favorite(ruleId);
        break;
      default:
        return;
    }

    this._updateFavorites();
  };

  _renderSelectedRules = () => {
    const {
      selectedRules,
      federalFilter,
      regulatoryFilter,
      districtStateFilter,
      favorites,
      regulatoryAgencies,
      federalRuleCategories,
      stateRuleCategoryFilter,
      districtStates,
      abaRules,
      aaaRules,
      internationalRules,
      districtFilter,
      loading,
    } = this.state;

    let selectedState;

    switch (selectedRules) {
      case 'Federal':
        return (
          <FederalRules
            federalRuleCategories={federalRuleCategories.filter(
              (category) => (federalFilter === 'All' ? true : category.name === federalFilter),
            )}
            toggleRuleBookmark={this._toggleRuleBookmark}
            favorites={favorites}
            loading={loading}
          />
        );
      case 'District courts':
        return (
          <DistrictRules
            districtStates={districtStateFilter === '' ? null : districtStates.filter(
              (districtState) => districtState.name === districtStateFilter,
            )}
            districtFilter={districtFilter}
            toggleRuleBookmark={this._toggleRuleBookmark}
            favorites={favorites}
            loading={loading}
          />
        );
      case 'State courts':
        selectedState = this._filterStates();
        return (
          <StateRules
            states={selectedState}
            stateRuleCategories={selectedState && selectedState[0]
              ? selectedState[0].stateRuleCategories.filter((category) => (stateRuleCategoryFilter === 'All' ? true : category.name === stateRuleCategoryFilter))
              : []}
            toggleRuleBookmark={this._toggleRuleBookmark}
            favorites={favorites}
            loading={loading}
          />
        );
      case 'Regulatory':
        return (
          <RegulatoryRules
            regulatoryAgencies={regulatoryAgencies.filter(
              (agency) => (regulatoryFilter === 'All' ? true : agency.name === regulatoryFilter),
            )}
            toggleRuleBookmark={this._toggleRuleBookmark}
            favorites={favorites}
            loading={loading}
          />
        );
      case 'ABA':
        return (
          <AbaRules
            abaRules={abaRules}
            toggleRuleBookmark={this._toggleRuleBookmark}
            favorites={favorites}
            loading={loading}
          />
        );
      case 'AAA':
        return (
          <AAARules
            aaaRules={aaaRules}
            toggleRuleBookmark={this._toggleRuleBookmark}
            favorites={favorites}
            loading={loading}
          />
        );
      case 'International':
        return (
          <InternationalRules
            internationalRules={internationalRules}
            toggleRuleBookmark={this._toggleRuleBookmark}
            favorites={favorites}
            loading={loading}
          />
        );
      default:
        return null;
    }
  };

  _renderSelectedRulesNavigator = () => {
    const {
      selectedRules,
      federalFilter,
      regulatoryFilter,
      regulatoryAgencies,
      federalRuleCategories,
      districtStates,
      districtStateFilter,
      stateRuleCategoryFilter,
    } = this.state;

    const { allStates } = this.props;
    let selectedState;

    switch (selectedRules) {
      case 'Federal':
        return (
          <FederalRulesNavigator
            federalRuleCategories={federalRuleCategories}
            setFederalFilter={this._setFederalFilter}
            federalFilter={federalFilter}
          />
        );
      case 'District courts':
        return (
          <DistrictRulesNavigator
            districtStates={districtStates}
            setDistrictStateFilter={this._setDistrictStateFilter}
            selectDistrict={this._setDistrictFilter}
            allStates={allStates}
            districtStateFilter={districtStateFilter}
          />
        );
      case 'State courts':
        selectedState = this._filterStates();
        return (
          <StateRulesNavigator
            allStates={allStates}
            states={selectedState}
            setStateFilter={this._setStateFilter}
            setStateRuleCategoryFilter={this._setStateRuleCategoryFilter}
            stateRuleCategoryFilter={stateRuleCategoryFilter}
          />
        );
      case 'Regulatory':
        return (
          <RegulatoryRulesNavigator
            regulatoryAgencies={regulatoryAgencies}
            setRegulatoryFilter={this._setRegulatoryFilter}
            regulatoryFilter={regulatoryFilter}
          />
        );
      case 'ABA':
      case 'AAA':
      case 'International':
        return null;
      default:
        return null;
    }
  };

  mobileHeaderToggle = () => {
    const { mobileOpened } = this.state;
    // disable page scroll if mobile header opened
    if (!mobileOpened) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';
    this.setState({ mobileOpened: !mobileOpened });
  };

  render() {
    const { selectedRules, mobileOpened } = this.state;
    const ruleCategories = ['Federal', 'District courts', 'State courts', 'Regulatory', 'ABA'];
    return (
      <div className="react-rules">
        <div className="mobile-search-open" onClick={this.mobileHeaderToggle} />
        <div className={`left ${mobileOpened ? 'opened' : ''}`}>
          <div className="title hide-mobile">
            Rules
          </div>
          <div className="rules-description">
            We&apos;ve identified and included the rules impacting ediscovery for all federal, state, and district courts as well as regulatory agencies and the ABA. Court guidelines are also included. We&apos;ve tagged rules by issue so that you can find what you need in just a few clicks.
          </div>
          <div className="mobile-rules-select">
            { ruleCategories.map((category) => (
              <a
                key={category}
                className={`mobile-category ${selectedRules === category ? 'selected' : ''}`}
                href={`/rules?selectedRules=${category}`}
              >
                { category }
              </a>
            ))}
          </div>
          <div className="rules-select">
            <select onChange={this._selectRules} value={selectedRules}>
              <option value="Federal">Federal</option>
              <option value="District courts">District courts</option>
              <option value="State courts">State courts</option>
              <option value="Regulatory">Regulatory</option>
              <option value="ABA">ABA</option>
              <option value="AAA">American Arbitration Association (AAA)</option>
              <option value="International">International</option>
            </select>
          </div>
          {this._renderSelectedRulesNavigator()}
        </div>
        <div className="right">
          {this._renderSelectedRules()}
        </div>
        <ReactTooltip effect="solid" html delayHide={1000} />
      </div>
    );
  }
}

Rules.propTypes = {
  favorites: PropTypes.object.isRequired,
  allStates: PropTypes.array.isRequired,
};

export default Rules;
