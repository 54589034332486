import React from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import Header from './Header';
import Hero from './Hero';
import Filters from './Filters';
import Item from './Item';
import { renderTotalPages } from '../../services/pages';

export default function Progress({
  currentUser, progressItems, isAdmin, currentPage, totalCount, totalPages, tab, tags, topics, academySubscription, subscription, userSession,
}) {
  const activeTab = tab || 'progress';

  return (
    <div className="react-lc-page lc-bookmarks">
      <Header currentUser={currentUser} isAdmin={isAdmin} academySubscription={academySubscription} userSession={userSession} />
      <Hero
        title="My Progress"
        subtitle="This page tracks your progress through the content in eDiscovery Academy."
      >
        <Filters tags={tags} topics={topics} />
      </Hero>
      { progressItems?.length > 0 && (
        <div className="results-count">
          { (currentPage - 1) * 25 + 1 }
          {' - '}
          { Math.min(currentPage * 25, totalCount || 0) }
          {' of '}
          {totalCount || 0}
          {' '}
          result
          { totalCount > 1 ? 's' : ''}
        </div>
      )}
      <div className="section">
        <div className="lc-progress-tabs">
          <a
            className={`lc-progress-tab ${activeTab === 'progress' && 'active'}`}
            href={`${window.location.pathname}?tab=progress`}
          >
            <span>In Progress</span>
          </a>
          <a
            className={`lc-progress-tab ${activeTab === 'completed' && 'active'}`}
            href={`${window.location.pathname}?tab=completed`}
          >
            <span>Completed</span>
          </a>
        </div>

        { progressItems?.length < 1 ? (
          <div className="section">
            { activeTab === 'progress' ? (
              <div className="empty">
                You have not started reviewing content in eDiscovery Academy.
                {' '}
                <br />
                As you review content, it will appear on this page to allow you
                to track what you have viewed or read.
              </div>
            ) : (
              <div className="empty">
                You have not completed any content in eDiscovery Academy.
                {' '}
                <br />
                After you review content in its entirety, it will appear on this page to allow you to track what you have viewed or read.
              </div>
            )}
          </div>
        ) : (
          <div className="lc-progress-items">
            { progressItems?.map((item) => (
              <Item data={item.media_item} key={item.id} breadcrumb="progress" isAdmin={isAdmin} isAcademy={academySubscription} subscription={subscription} />
            ))}
          </div>
        )}

        { progressItems?.length > 0 && (
        <div className="pagination">
          <a
            className={`page arrow ${currentPage <= 1 && 'disabled'}`}
            href={`${window.location.pathname}?page=${currentPage - 1}&tab=${activeTab}`}
          >
            <FiChevronLeft />
          </a>
          {renderTotalPages(totalPages, currentPage, activeTab)}
          <a
            className={`page arrow ${currentPage >= totalPages && 'disabled'}`}
            href={`${window.location.pathname}?page=${currentPage + 1}&tab=${activeTab}`}
          >
            <FiChevronRight />
          </a>
        </div>
        ) }
      </div>
    </div>
  );
}
